import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddNotaConstatareModal from "./notaConstatareAdd";

const ViewEditFileModal = ({
  open,
  onClose,
  contractId,
  currentFile,
  BASE_URL,
  FILE_UPLOAD_PATH,
  handleChangeDatas,
  contracts
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [addFileModalOpen, setAddFileModalOpen] = useState(false);
  const [selectedFileURL, setSelectedFileURL] = useState("");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false); // Starea pentru dialogul de confirmare

  // Încarcă fișierul curent (de pe server) la deschiderea modalului
  useEffect(() => {
    if (open) {
      setSelectedFileURL(
        currentFile ? `${BASE_URL}/${FILE_UPLOAD_PATH}/${currentFile}` : ""
      );
      setSelectedFile(null); // Resetează fișierul selectat
    } else {
      setSelectedFile(null);
      setSelectedFileURL("");
    }
  }, [open, currentFile, BASE_URL, FILE_UPLOAD_PATH]);

  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Obține fișierul selectat
    if (file) {
      setSelectedFile(file); // Stochează fișierul în stare
      const fileURL = URL.createObjectURL(file);
      setSelectedFileURL(fileURL); // Stochează URL-ul pentru previzualizare
    } else {
      setSelectedFile(null);
      setSelectedFileURL("");
    }
  };
  console.log("currentFile", currentFile);
  useEffect(() => {
    if (currentFile === null || currentFile === "") {
      setSelectedFileURL(""); // Resetează URL-ul la fiecare actualizare a fișierului curent
    }
  }, [currentFile]);

  const handleUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      const notaConstatare = document.getElementById("notaConstatare").files[0];
      const specificContract = contracts.find(contract => contract.idContract === contractId);
      console.log("specificContract", specificContract.notaConstatare);

      if (notaConstatare) {
        formData.append("notaConstatare", notaConstatare);
        formData.append("contractId", contractId);
        formData.append("oldFileNameNotaConstatare", specificContract.notaConstatare);

        try {
          const response = await fetch(`${BASE_URL}/addNotaConstatare?contractId=${contractId}`, {
            method: "POST",
            body: formData,
            credentials: "include"
          });

          if (response.ok) {
            console.log("Fișierul a fost urcat cu succes!");
            handleChangeDatas();
            onClose(); // Închide modalul după upload
          } else {
            console.error("Eroare la urcarea fișierului");
          }
        } catch (error) {
          console.error("Eroare de rețea:", error);
        }
        console.log("Fișierul a fost încărcat: ", selectedFile);
        onClose();
      }
    }
  };

  const handleDeleteFile = () => {
    setConfirmDeleteOpen(true); // Deschide dialogul de confirmare
  };

  const handleActualDeleteFile = async () => {
    if (currentFile) {
      try {
        const response = await fetch(`${BASE_URL}/deleteNotaConstatare`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ contractId, fileName: currentFile }),
          credentials: "include"
        });

        if (response.ok) {
          console.log("Fișierul a fost șters cu succes.");
          handleChangeDatas();
          onClose(); // Închide modalul după ștergere
        } else {
          console.error("Eroare la ștergerea fișierului");
        }
      } catch (error) {
        console.error("Eroare de rețea:", error);
      }
      setConfirmDeleteOpen(false); // Închide dialogul de confirmare
    }
  };

  // Curățarea URL-ului
  useEffect(() => {
    console.log("selectedFileURL:", selectedFileURL);
    return () => {
      if (selectedFileURL) {
        URL.revokeObjectURL(selectedFileURL); // Curăță URL-ul temporar
      }
    };
  }, [selectedFileURL]);

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            height: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 0,
            borderRadius: "10px",
            overflow: "hidden",
            padding: "32px",
            position: "relative"
          }}
        >
          {selectedFileURL || currentFile ? (
            selectedFileURL ? (
              selectedFileURL.endsWith(".pdf") ? (
                <embed
                  src={selectedFileURL}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain"
                  }}
                  type="application/pdf"
                />
              ) : (
                <img
                  src={selectedFileURL}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain"
                  }}
                  alt="Preview"
                />
              )
            ) : currentFile.endsWith(".pdf") ? (
              <embed
                src={`${BASE_URL}/${FILE_UPLOAD_PATH}/${currentFile}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain"
                }}
                type="application/pdf"
              />
            ) : (
              <img
                src={`${BASE_URL}/${FILE_UPLOAD_PATH}/${currentFile}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain"
                }}
                alt="Preview"
              />
            )
          ) : (
            <div style={{ textAlign: "center" }}>
              <p>Nu există fișier disponibil pentru vizualizare.</p>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setAddFileModalOpen(true);
                }}
              >
                Adaugă Fișier
              </Button>
            </div>
          )}

          {(currentFile || selectedFile) && (
            <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
              <Button variant="contained" component="label">
                Editeaza
                <input
                  type="file"
                  hidden
                  onChange={handleFileChange}
                  id="notaConstatare"
                  name="notaConstatare"
                />
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleUpload}
                disabled={!selectedFile} // Activare doar dacă există un fișier selectat
              >
                Salveaza
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleDeleteFile}
              >
                Sterge
              </Button>
            </div>
          )}

          <Button
            variant="contained"
            color="inherit"
            onClick={onClose}
            style={{
              position: "absolute",
              top: "7px",
              right: "7px",
              minWidth: "0",
              padding: "0",
              color: "red",
              backgroundColor: "transparent",
              boxShadow: "none"
            }}
          >
            <CloseIcon />
          </Button>
        </Box>
      </Modal>

      {/* Dialogul de confirmare pentru ștergere */}
      <Dialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
      >
        <DialogTitle>Confirmare ștergere</DialogTitle>
        <DialogContent>
          <p>Ești sigur că vrei să ștergi acest fișier?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteOpen(false)} color="primary">
            Anulează
          </Button>
          <Button
            onClick={handleActualDeleteFile} // Funcția care efectuează ștergerea
            color="error"
          >
            Șterge
          </Button>
        </DialogActions>
      </Dialog>

      <AddNotaConstatareModal
        open={addFileModalOpen}
        onClose={() => {
          setAddFileModalOpen(false);
          onClose();
        }}
        contractId={contractId}
        handleChangeDataAdd={handleChangeDatas}
      />
    </>
  );
};

export default ViewEditFileModal;
