import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  MenuItem
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import Axios from "axios";
import bcrypt from "bcryptjs";

function AddUserComponent({ open, handleClose, userId, userRole }) {
  const [inputFields, setInputFields] = useState({
    nume: "",
    prenume: "",
    email: "",
    parola: "",
    parolaConfirm: "",
    role: "",
    managerId: ""
  });
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [managersList, setManagersList] = useState([]);

  const commissions = [
    {
      value: "1",
      commission: "2",
      label: "Administrator"
    },
    {
      value: "2",
      commission: "1",
      label: "Manager"
    },
    {
      value: "3",
      commission: "10",
      label: "Agent"
    },
    {
      value: "4",
      commission: "0",
      label: "Operator"
    }
  ];

  const validateValues = (inputValues) => {
    let errors = {};
    if (inputValues.nume.length === 0) {
      errors.nume = "Completeaza numele";
    }
    if (inputValues.prenume.length === 0) {
      errors.prenume = "Completeaza prenume";
    }
    if (inputValues.email.length === 0) {
      errors.email = "Completeaza email-ul";
    }
    if (inputValues.parola.length === 0) {
      errors.parola = "Completeaza parola";
    }
    if (inputValues.parolaConfirm.length === 0) {
      errors.parolaConfirm = "Confirma parola!";
    }
    if (inputValues.parola !== inputValues.parolaConfirm) {
      errors.parolaNotEqual = "Parolele nu sunt identice!";
    }
    if (inputValues.role.length === 0) {
      errors.role = "Selecteaza rolul!";
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setInputFields((prevFields) => {
      // Dacă este selectat rolul Agent, managerId trebuie să fie gol pentru a fi selectat manual
      if (name === "role" && value === "3") {
        return {
          ...prevFields,
          [name]: value,
          managerId: "" // managerId va fi selectat manual ulterior
        };
      }

      // Dacă este selectat alt rol decât Agent, managerId devine userId (persoana logată)
      if (name === "role" && value !== "3") {
        return {
          ...prevFields,
          [name]: value,
          managerId: userId
        };
      }

      // Pentru celelalte câmpuri (inclusiv selectul pentru manager)
      return {
        ...prevFields,
        [name]: value
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validateValues(inputFields));
    setSubmitting(true);
  };

  const finishSubmit = async () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const saltRounds = 12;
    const myPlaintextPassword = inputFields.parola;
    const salt = bcrypt.genSaltSync(saltRounds);
    const hash = bcrypt.hashSync(myPlaintextPassword, salt);

    const userVerify = {
      username: inputFields.email
    };
    await Axios.post(`${BASE_URL}/userVerify`, userVerify).then((res) => {
      if (res.data.length > 0) {
        setErrors({ emailExists: "Exista deja un utilizator cu acest email!" });
        return;
      } else {
        const userData = {
          nume: inputFields.nume,
          prenume: inputFields.prenume,
          parola: hash,
          username: inputFields.email,
          role: inputFields.role,
          managerId: inputFields.role === "3" ? inputFields.managerId : userId,
          comision: commissions.find(
            (commission) => commission.value === inputFields.role
          ).commission
        };
        // console.log({ userData: userData });
        Axios.post(`${BASE_URL}/addUser`, userData).then((res) => {
          console.log({ res });
        });

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit();
    }
  }, [errors]);

  useEffect(() => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    if (inputFields.role === "3") {
      if (userRole === 1) {
        Axios.get(`${BASE_URL}/getManagersList`)
          .then((res) => {
            setManagersList(res.data);
          })
          .catch((error) => {
            console.error("Error fetching managers list:", error);
          });
      } else if (userRole === 2 && userId) {
        Axios.get(`${BASE_URL}/getManagersListByParentId/${userId}`)
          .then((res) => {
            setManagersList(res.data);
          })
          .catch((error) => {
            console.error("Error fetching managers list by userId:", error);
          });
      }
    }
  }, [inputFields.role, userRole, userId]);

  useEffect(() => {
    // console.log("userRole:", userRole);
    // console.log("userId:", userId);

    const BASE_URL = process.env.REACT_APP_BASE_URL;
    if (userRole === 1) {
      Axios.get(`${BASE_URL}/getManagersList`)
        .then((res) => {
          setManagersList(res.data);
        })
        .catch((error) => {
          console.error("Error fetching managers list:", error);
        });
    } else if (userRole === 2 && userId) {
      Axios.get(`${BASE_URL}/getManagersListByParentId/${userId}`)
        .then((res) => {
          setManagersList(res.data);
        })
        .catch((error) => {
          console.error("Error fetching managers list by userId:", error);
        });
    }
  }, [userRole, userId]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>
        Adauga utilizator
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            label="Nume"
            name="nume"
            value={inputFields.nume}
            onChange={handleChange}
            fullWidth
            error={Boolean(errors.nume)}
            helperText={errors.nume}
          />
          <TextField
            margin="normal"
            label="Prenume"
            name="prenume"
            value={inputFields.prenume}
            onChange={handleChange}
            fullWidth
            error={Boolean(errors.prenume)}
            helperText={errors.prenume}
          />
          <TextField
            margin="normal"
            label="Email"
            name="email"
            value={inputFields.email}
            onChange={handleChange}
            fullWidth
            error={Boolean(errors.email) || Boolean(errors.emailExists)}
            helperText={errors.email || errors.emailExists}
          />
          <TextField
            margin="normal"
            label="Parola"
            type="password"
            name="parola"
            value={inputFields.parola}
            onChange={handleChange}
            fullWidth
            error={Boolean(errors.parola)}
            helperText={errors.parola}
          />
          <TextField
            margin="normal"
            label="Confirma parola"
            type="password"
            name="parolaConfirm"
            value={inputFields.parolaConfirm}
            onChange={handleChange}
            fullWidth
            error={
              Boolean(errors.parolaConfirm) || Boolean(errors.parolaNotEqual)
            }
            helperText={errors.parolaConfirm || errors.parolaNotEqual}
          />
          <TextField
            margin="normal"
            select
            label="Selecteaza rolul"
            name="role"
            value={inputFields.role}
            onChange={handleChange}
            fullWidth
            error={Boolean(errors.role)}
            helperText={errors.role}
          >
            {userRole === 1 ? (
              [
                <MenuItem key="1" value="1">
                  Administrator
                </MenuItem>,
                <MenuItem key="2" value="2">
                  Manager
                </MenuItem>,
                <MenuItem key="3" value="3">
                  Agent
                </MenuItem>,
                <MenuItem key="4" value="4">
                  Operator
                </MenuItem>
              ]
            ) : (
              <MenuItem key="3" value="3">
                Agent
              </MenuItem>
            )}
          </TextField>

          {inputFields.role === "3" && (
            <TextField
              margin="normal"
              select
              label="Selecteaza manager"
              name="managerId"
              value={inputFields.managerId}
              onChange={handleChange}
              fullWidth
            >
              {managersList.map((manager) => (
                <MenuItem key={manager.id} value={manager.id}>
                  {manager.nume} {manager.prenume}
                </MenuItem>
              ))}
            </TextField>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Anuleaza
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="success">
          <AddIcon />
          Adauga utilizator
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddUserComponent;
